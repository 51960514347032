import React from "react";
import Layout from "../components/layout";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

const Certificates = () => {

  const dataQuery = useStaticQuery(graphql`
    query Certificates {
      allStrapiCertificate(sort: { order: ASC, fields: sort }) {
        group(field: sort) {
          edges {
            node {
              id
              images {
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
              strapi_id
              title
              type_certificate
              sort
            }
          }
        }
      }
    }
  `);

  const allData = dataQuery.allStrapiCertificate.group;


  return (
    <Layout>
      <div className="container content_header  mt-60">
        <h1 className="text-center">СЕРТИФИКАТЫ:</h1>
        <div className="info mt-30 mb-30 ">
          <p className="p20">
          Вся продукция DENDOR сертифицирована, имеет сертификаты соответствия, технические паспорта.
            <br />
            Оригиналы документов, сертификаты на трубопроводную арматуру выдаются при поставке товара заказчику либо при выдаче товара непосредственно со склада.
            <br />
            <br /> При отсутствии сертификатов или технических паспортов на
            поставленную продукцию обращайтесь к менеджерам компании любым
            удобным для Вас способом, указанным на странице <Link to="/contacts">Контакты</Link>
          </p>
        </div>
        <div>
          <ul className="sert__list">
            <li>
              <a href="#1">ГОСТ Р ИСО 9001-2015 (ISO 9001:2015) </a>
            </li>
            <li>
              <a href="#2">
                ТР ТС 010/2011 «О безопасности машин и оборудования»{" "}
              </a>
            </li>
            <li>
              <a href="#3">
                ТР ТС 032/2013 «О безопасности оборудования работающего под
                избыточным давлением»
              </a>
            </li>
            <li>
              <a href="#4">
                ТР ТС 004/2011 «О безопасности низковольтного оборудования», ТР
                ТС 020/2011 «Электромагнитная совместимость технических средств»
              </a>
            </li>
            <li>
              <a href="#5">
                ТР ЕАЭС 043/2017 «О требованиях к средствам обеспечения пожарной
                безопасности и пожаротушения» (Обязательная сертификация).
              </a>
            </li>
            <li>
              <a href="#6">
                ГОСТ Р 51052-2002 «Установки водяного и пенного пожаротушения
                автоматические. Узлы управления. Общие технические требования.
                Методы испытаний» (Добровольная сертификация)
              </a>
            </li>
            <li>
              <a href="#7">Гигиенические сертификаты (Экспертные заключения)</a>
            </li>
            <li>
              <a href="#8">
                Гигиенические сертификаты (Свидетельства о Государственной
                регистрации СГР)
              </a>
            </li>
            <li>
              <a href="#9">
                ГОСТ 30546.1-98 (Исполнение сейсмостойкости 9 баллов по шкале
                MSK-64)
              </a>
            </li>
            <li>
              <a href="#10">СТ-1 Сертификат о происхождении товара</a>
            </li>
            <li>
              <a href="#11">Продукция не подлежащая сертификации</a>
            </li>
          </ul>
        </div>

        {allData.map((group, i) => {
          return (
            <div key={i} className="sert">
              <div id={i + 1} className="sert__title">
                {group.edges[0].node.type_certificate}
              </div>
              <div className="sert__body">
                {group.edges.map((data, i) => {
                  const { id, images, sort, strapi_id, title } = data.node;

                  return (
                    <>
                      {images.map((img) => {
                        const image = getImage(img.localFile);
                        let key = img.localFile.childImageSharp.id;
                  
                        return (
                          <div
                            className={`sert_content sert${
                              images.length > 2 ? "_three" : "_two"
                            }`}
                          >
                            <div className="sert__imgTitle">{title}</div>
                            <div className="sert__img">
                              <a
                                target="_blank"
                                href={image.images.fallback.src}
                              >
                                <GatsbyImage
                                  placeholder="#fff"
                                  image={image}
                                  alt={title}
                                />
                              </a>
                            </div>
                          </div>
                        );
                      })}
                    </>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>

      <div className="container mt-30"></div>
    </Layout>
  );
};

export default Certificates;
